import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Nuestro Compromiso' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Nos tomamos muy en serio nuestro compromiso con nuestros usuarios. 
        Si necesita nuestra ayuda con su proyecto, tiene preguntas sobre cómo utilizar el sitio o está
        experimenta alguna dificultad técnica, no dude en Contáctenos.
      </Text>
    </Card>
  </Section>
)

export default Commitment
